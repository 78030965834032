import request from '@/utils/request'

//门店楼层分页列表
export function floorListAPI (params) {
  return request({
    url: '/api/system/o2othird/ordermeal/shop/floor/list',
    method: 'GET',
    params
  })
}

//新增门店楼层
export function floorSaveAPI (data) {
  return request({
    url: '/api/system/o2othird/ordermeal/shop/floor/save',
    method: 'POST',
    data
  })
}

//修改门店楼层
export function floorUpdateAPI (data) {
  return request({
    url: '/api/system/o2othird/ordermeal/shop/floor/update',
    method: 'PUT',
    data
  })
}

//删除门店楼层
export function floorRemoveAPI (data) {
  return request({
    url: '/api/system/o2othird/ordermeal/shop/floor/deleteFloor',
    method: 'DELETE',
    data
  })
}

//添加桌台
export function saveFloorTableAPI (data) {
  return request({
    url: '/api/system/o2othird/ordermeal/shop/floor/saveFloorTable',
    method: 'POST',
    data
  })
}

//修改桌台
export function updateFloorTableAPI (data) {
  return request({
    url: '/api/system/o2othird/ordermeal/shop/floor/updateFloorTable',
    method: 'PUT',
    data
  })
}

//删除桌台
export function deleteTableAPI (data) {
  return request({
    url: '/api/system/o2othird/ordermeal/shop/floor/deleteTable',
    method: 'DELETE',
    data
  })
}

//获取楼层桌台码
export function getTableCodeAPI (params) {
  return request({
    url: '/api/system/o2othird/ordermeal/shop/floor/getTableCode',
    method: 'GET',
    timeout: 30000,
    params
  })
}
